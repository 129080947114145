<template>
  <div class="worker__profile">
    <!-- Basic Details Section -->
    <SectionTitle
      title="Basic Details"
      actionLabel="Edit"
      actionIcon="mdi-pencil"
      @onClick="handleEdit('Edit Worker Basic Details', 1)"
      :isLoading="isLoading"
      :canEdit="canEdit"
      v-if="canViewBasicDetails"
    />
    <div class="mt-2" v-if="isLoading">
      <v-skeleton-loader
        v-for="i in 3"
        :key="i"
        width="100%"
        type="heading, list-item"
      />
      <v-skeleton-loader width="100%" type="heading, list-item-three-line" />
    </div>
    <div v-else>
      <template v-if="isPermitted('workers.view.email')">
        <h3>Email</h3>
        <p>{{ currentWorker.email | hasValue }}</p>
      </template>
      <template v-if="isPermitted('workers.view.phone-number')">
        <h3>Phone Number</h3>
        <p>{{ currentWorker.phone | hasValue }}</p>
      </template>
      <template v-if="isPermitted('workers.view.dob')">
        <h3>Date of Birth</h3>
        <p>{{ this.formatDataDate(currentWorker.dob) | hasValue }}</p>
      </template>
      <template v-if="isPermitted('workers.view.address')">
        <h3>Address</h3>
        <p v-if="!emptyAddress">-</p>
        <template v-else>
          <div
            class="addresses"
            v-for="(address, index) in currentWorker.addresses"
            :key="index"
          >
            <p>
              {{ address.line1 }}, {{ address.line2 }}, {{ address.line3 }},
              {{ address.postCode }}
            </p>
          </div>
        </template>
      </template>
      <div v-if="organisation">
        <h3>Agency Supplier</h3>
        <p>{{ organisation.name }}</p>
      </div>
    </div>
    <hr />
    <!-- Income Type Section -->
    <SectionTitle
      title="Income Type"
      actionLabel="Edit"
      actionIcon="mdi-pencil"
      @onClick="handleEdit('Edit Worker Income Type', 2)"
      :isLoading="isLoading"
      :canEdit="canEdit"
      v-if="isPermitted('workers.view.income-type')"
    />
    <div v-if="isLoading">
      <v-skeleton-loader width="100%" type="card-heading, chip" />
      <v-skeleton-loader
        v-for="i in 2"
        :key="i"
        width="100%"
        type="card-heading, list-item"
      />
      <v-skeleton-loader width="100%" type="heading, list-item-three-line" />
    </div>
    <div v-else-if="isPermitted('workers.view.income-type')">
      <h3>Type</h3>
      <p v-if="this.worker.incomeType === null">-</p>
      <Permissioned section="workers.view" vertical v-else>
        <v-chip-group permission="income-type" class="mb-3" column>
          <v-chip>{{ this.worker.incomeType | hasValue }}</v-chip>
        </v-chip-group>
      </Permissioned>
      <h3>Company Name</h3>
      <p v-if="!hasIntermediary">-</p>
      <template v-else>
        <div>
          <p>{{ workerIntermediaryCompany.name | hasValue }}</p>
          <h3>Company Registration Number</h3>
          <p>
            {{ workerIntermediaryCompany.companyRegistrationNumber | hasValue }}
          </p>
        </div>
      </template>
      <h3>Address</h3>
      <p v-if="!emptyIntermediaryAddress">-</p>
      <template v-else>
        <div class="addresses">
          <p>{{ workerIntermediaryCompanyAddress.line1 }}</p>
          <p>{{ workerIntermediaryCompanyAddress.line2 }}</p>
          <p>{{ workerIntermediaryCompanyAddress.line3 }}</p>
          <p>{{ workerIntermediaryCompanyAddress.postCode }}</p>
        </div>
      </template>
    </div>
    <hr />
    <!-- Job Details Section -->
    <SectionTitle
      title="CV"
      actionLabel="Edit"
      actionIcon="mdi-pencil"
      @onClick="handleEdit('Edit Worker Experience', 3)"
      :isLoading="isLoading"
      :canEdit="canEdit"
      v-if="canViewCV"
    >
      <v-btn
        class="mb-1"
        text
        color="primary"
        @click="handleViewCV"
        v-if="currentWorker.cv"
      >
        {{ this.btnLabel }}
        <v-icon class="ml-3">mdi-fullscreen</v-icon>
      </v-btn>
    </SectionTitle>
    <div class="mb-3" v-if="isLoading">
      <v-skeleton-loader width="100%" type="card-heading, image" />
    </div>
    <div v-else-if="canViewCV">
      <div v-if="!isPDF" class="worker__profile--cv">
        <p class="secondary-text">
          Click download button to view the file.
        </p>
      </div>
      <div v-else>
        <embed
          v-if="currentWorker.cv"
          :src="cvUrlWithToken"
          :style="styles"
          :type="currentWorker.cv.mimeType"
        />
        <p v-else>-</p>
      </div>
    </div>
    <hr />
    <!-- Compliance Section -->
    <Permissioned section="workers.view" vertical>
      <SectionTitle
        title="Compliance Checks"
        actionLabel="Edit"
        actionIcon="mdi-pencil"
        @onClick="handleEdit('Edit Worker Compliance', 4, true)"
        :isLoading="isLoading"
        :canEdit="canEdit && canEditComplianceChecks"
        :subText="completedCompliance"
        permission="compliance-checks"
      />
      <div v-if="isLoading">
        <v-skeleton-loader
          v-for="i in 14"
          :key="i"
          width="100%"
          type="list-item-avatar"
        />
      </div>
      <template v-else>
        <ComplianceCheckList
          class="mb-4"
          permission="compliance-checks"
          :checks="worker.complianceChecks"
          :references="worker.references"
        />
        <h3>Supporting Document</h3>
        <p v-if="!complianceSupportingFile">-</p>
        <div class="mt-2" v-else>
          <FileComponent
            permission="compliance-checks"
            :isRemoving="isRemoving"
            :key="complianceSupportingFile.id"
            :file="complianceSupportingFile"
            @removeFile="handleRemoveFile"
            @show="showComplianceSupportingFile"
            disabled
            customActions
          />
        </div>
      </template>
    </Permissioned>
    <hr />
    <!-- Diversity Section -->
    <SectionTitle
      title="Diversity"
      actionLabel="Edit"
      actionIcon="mdi-pencil"
      @onClick="handleEdit('Edit Worker Diversity', 7)"
      :isLoading="isLoading"
      :canEdit="canEdit"
      v-if="canViewDiversity"
    />
    <div class="mt-2" v-if="isLoading">
      <v-skeleton-loader
        v-for="i in 4"
        :key="i"
        width="100%"
        type="heading, list-item"
      />
    </div>
    <div v-else>
      <template v-if="isPermitted('workers.view.gender')">
        <h3>Gender</h3>
        <ShowHideText>{{
          currentWorker.gender | hasValue | capitalize
        }}</ShowHideText>
      </template>
      <template v-if="isPermitted('workers.view.ethnic-origin')">
        <h3>Ethnic Origin</h3>
        <ShowHideText>{{ ethnicOrigin }}</ShowHideText>
      </template>
      <template v-if="isPermitted('workers.view.country-of-birth')">
        <h3>Country of Birth</h3>
        <ShowHideText>{{ countryOfBirth }}</ShowHideText>
      </template>
      <Permissioned section="workers.view" vertical>
        <h3 permission="dda">
          Does Disability Discrimination Act Apply?
        </h3>
        <ShowHideText permission="dda">{{
          currentWorker.dda | ddaAnswer
        }}</ShowHideText>
      </Permissioned>
    </div>
    <!-- New Worker Dialog -->
    <DialogFull
      :steps="dialogSteps"
      :progress="isProgress"
      :data="updateDialog"
      @onStepBack="stepBack"
      @close="handleCancelEdit"
    >
      <template v-slot:dialog.body>
        <NewWorkerForm
          ref="form"
          :steps="steps"
          :worker="workerData"
          isUpdate
          :showValidationErrors="showValidationErrors"
          :permissions="permissions"
          :key="formKey"
          @onChange="handleChange"
        />
      </template>
      <template v-slot:dialog.action>
        <PrimaryButton
          v-if="dialogSteps.current === dialogSteps.total || !isProgress"
          @click.native="handleSaveWorker"
          :loading="isSaving"
        >
          Save Changes
        </PrimaryButton>
        <PrimaryButton @click.native="nextStep" v-else>
          Continue
        </PrimaryButton>
      </template>
    </DialogFull>
    <PDFViewFull :data="fileDialog" />
  </div>
</template>

<script>
import SectionTitle from "@/components/workers/common/SectionTitle";
import FileComponent from "@/components/common/FileComponent";
import DialogFull from "@/components/common/DialogFull";
import PDFViewFull from "@/components/common/PDFViewFull";
import NewWorkerForm from "@/components/workers/NewWorkerForm";
import ShowHideText from "@/components/common/ShowHideText";
import {
  filter,
  first,
  isEmpty,
  map,
  union,
  remove,
  size,
  includes
} from "lodash";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import {
  getFormattedReferences,
  getFormattedComplianceChecks,
  getWorkerData,
  getCompanyData,
  getAddressData
} from "@/utils/workers";
import { formatDate } from "@/utils/time";
import {
  VALIDATIONS,
  INIT_WORKER_DATA,
  COMPLIANCE_NAMES,
  CREATE_WORKER_STEPS
} from "@/constants/workers";
import {
  UPDATE_WORKER,
  GET_INTERMEDIARY_COMPANY,
  ADD_NEW_REFERENCE,
  DELETE_REFERENCE,
  DELETE_WORKER_COMPLIANCE_DOC
} from "@/store/modules/workers/action-types";
import {
  ADD_NEW_ADDRESS,
  UPDATE_ADDRESS
} from "@/store/modules/addresses/action-types";
import {
  CREATE_ORGANISATION,
  UPDATE_ORGANISATION
} from "@/store/modules/organisations/actions";
import { mapState, mapActions, mapMutations } from "vuex";
import { SET_WORKER_COMPLIANCE_FILE } from "@/store/modules/workers/mutation-types";
import Permissioned from "@/components/common/Permissioned";
import moment from "moment";
import { getToken } from "@/services/utils";
import ComplianceCheckList from "@/components/workers/common/ComplianceCheckList";

export default {
  components: {
    SectionTitle,
    FileComponent,
    DialogFull,
    NewWorkerForm,
    ComplianceCheckList,
    PDFViewFull,
    PrimaryButton,
    Permissioned,
    ShowHideText
  },
  mounted() {
    this.updatedWorker = this.workerData;
  },
  data() {
    return {
      showValidationErrors: false,
      isSaving: false,
      isProgress: false,
      fileDialog: { dialog: false },
      updateDialog: { dialog: false, title: "Edit Worker Basic Details" },
      steps: {
        current: 1,
        total: 7,
        list: CREATE_WORKER_STEPS
      },
      dialogSteps: {
        current: 1,
        total: process.env.VUE_APP_HIDE_COMPLIANCE_FOR_CSP !== "true" ? 3 : 2
      },
      isRemoving: false,
      updatedWorker: this.workerData,
      formKey: 0,
      isReferencesChecked: false
    };
  },
  computed: {
    ...mapState("workers", {
      currentWorker: state => state.currentWorker,
      intermediaryCompany: state => state.intermediaryCompany,
      isLoading: state => state.isFetchingData
    }),
    ...mapState("auth", {
      permissions: state => state.permissions
    }),
    worker() {
      const { address, company, companyAddress } = INIT_WORKER_DATA;
      const worker = getWorkerData(this.currentWorker);
      return {
        id: worker.id || this.$route.params.id,
        ...worker,
        address,
        company,
        companyAddress
      };
    },
    isPDF() {
      return (
        this.currentWorker.cv &&
        this.currentWorker.cv.mimeType === "application/pdf"
      );
    },
    btnLabel() {
      return this.currentWorker.cv && !this.isPDF ? "Download" : "Full-screen";
    },
    workerData() {
      const worker = Object.assign({}, this.worker);
      if (!this.isLoading) {
        if (size(this.worker.addresses) > 0) {
          worker.address = getAddressData(first(this.worker.addresses));
        }
        worker.references = getFormattedReferences(worker);
        worker.complianceChecks = getFormattedComplianceChecks(worker);
        if (!isEmpty(this.intermediaryCompany)) {
          worker.company = getCompanyData(this.intermediaryCompany);
          if (size(this.intermediaryCompany.addresses) > 0) {
            worker.companyAddress = getAddressData(
              first(this.intermediaryCompany.addresses)
            );
          }
        }
        worker.countryOfBirth = this.getCountryOfBirthId;
        worker.ethnicGroupId = this.getEthnicOriginId;
      }
      return worker;
    },
    verifiedComplianceCount() {
      return filter(
        this.worker.complianceChecks,
        compliance => compliance.checkVerified
      ).length;
    },
    completedCompliance() {
      const complianceCount = size(this.worker.complianceChecks);
      return ` (${this.verifiedComplianceCount} of ${complianceCount})`;
    },
    showCheckCount() {
      return this.checkedCount > 0;
    },
    styles() {
      return { width: "100%", height: "450px", marginBottom: "15px" };
    },
    complianceSupportingFile() {
      return this.currentWorker.complianceSupportingFile;
    },
    cvUrlWithToken() {
      const token = getToken();
      return `${this.currentWorker.cv.url}?token=${token}`;
    },
    complianceFileUrlWithAuth() {
      const token = getToken();
      return `${this.complianceSupportingFile.url}?token=${token}`;
    },
    emptyAddress() {
      return this.workerData.addresses.length > 0;
    },
    emptyIntermediaryAddress() {
      return this.hasIntermediary
        ? Boolean(this.workerData.companyAddress)
        : false;
    },
    hasIntermediary() {
      return (
        Boolean(this.workerData.company) &&
        this.workerData.incomeType !== "PAYE"
      );
    },
    getCountryOfBirthId() {
      if (this.currentWorker.countryOfBirth) {
        if (this.currentWorker.countryOfBirth.id) {
          return this.currentWorker.countryOfBirth.id;
        }
        return this.currentWorker.countryOfBirth;
      }
      return null;
    },
    getEthnicOriginId() {
      if (this.currentWorker.ethnicGroupId) {
        return this.currentWorker.ethnicGroupId;
      }
      return this.currentWorker.ethnicOrigin
        ? this.currentWorker.ethnicOrigin.id
        : null;
    },
    organisation() {
      return this.currentWorker.organisation;
    },
    ethnicOrigin() {
      return this.currentWorker.ethnicOrigin &&
        this.isPermitted("workers.view.ethnic-origin")
        ? this.currentWorker.ethnicOrigin.label
        : "-";
    },
    countryOfBirth() {
      return this.currentWorker.countryOfBirth &&
        this.isPermitted("workers.view.country-of-birth")
        ? this.currentWorker.countryOfBirth.label
        : "-";
    },
    canEdit() {
      return this.permissions["workers.edit"];
    },
    canViewCV() {
      return this.isPermitted("workers.view.cv");
    },
    canEditComplianceChecks() {
      return this.permissions["workers.edit.compliance-checks"];
    },
    workerIntermediaryCompany() {
      return this.workerData.company;
    },
    workerIntermediaryCompanyAddress() {
      return this.workerData.companyAddress;
    },
    canViewBasicDetails() {
      return (
        this.isPermitted("workers.view.email") ||
        this.isPermitted("workers.view.phone-number") ||
        this.isPermitted("workers.view.dob") ||
        this.isPermitted("workers.view.address") ||
        this.organisation
      );
    },
    canViewDiversity() {
      return (
        this.isPermitted("workers.view.gender") ||
        this.isPermitted("workers.view.ethnic-origin") ||
        this.isPermitted("workers.view.country-of-birth")
      );
    }
  },
  methods: {
    ...mapActions("workers", {
      updateWoker: UPDATE_WORKER,
      getIntermediaryCompany: GET_INTERMEDIARY_COMPANY,
      addNewReference: ADD_NEW_REFERENCE,
      deleteReference: DELETE_REFERENCE,
      deleteComplianceFile: DELETE_WORKER_COMPLIANCE_DOC
    }),
    ...mapActions("addresses", {
      addNewAddress: ADD_NEW_ADDRESS,
      updateAddress: UPDATE_ADDRESS
    }),
    ...mapActions("organisations", {
      createOrganisation: CREATE_ORGANISATION,
      updateOrganisation: UPDATE_ORGANISATION
    }),
    ...mapMutations("workers", {
      setWorkerComplianceFile: SET_WORKER_COMPLIANCE_FILE
    }),
    handleChange(worker) {
      this.updatedWorker = worker;
    },
    formatDataDate(date) {
      if (date) {
        date = moment(date).format("DD/MM/YYYY");
        return date;
      }
      return "";
    },
    handleEdit(title, currentStep, isProgress = false) {
      this.dialogSteps.current = 1;
      this.isProgress = isProgress;
      this.updateDialog = { dialog: true, title };
      this.steps.current = currentStep;
    },
    isPermitted(action) {
      return this.permissions[action];
    },
    async nextStep() {
      this.showValidationErrors = true;
      const {
        REFERENCES,
        RTW,
        FTF,
        DBS,
        HCPC,
        QTS,
        BL,
        ST,
        HS,
        CT,
        GDPRT,
        RF,
        CONTRACT,
        SMC,
        DL
      } = COMPLIANCE_NAMES;
      let {
        IS_VALID,
        RIGHT_TO_WORK,
        EMPLOYMENT_HISTORY,
        DBSV,
        HCPC_CCWW_SSSC,
        QTS_NCTL_QUALIFICATION,
        FACE_TO_FACE_INTERVIEW,
        BARRED_LIST,
        SAFEGUARDING,
        HEALTHANDSAFETY,
        CYBERSECURITY,
        GDPR_TRAINING,
        REGISTRATION_FORM,
        CONTRACT_VALIDATION,
        SOCIAL_MEDIA_CHECK,
        DRIVING_LICENCE
      } = VALIDATIONS;

      if (this.dialogSteps.current === 1) {
        this.isReferencesChecked = false;
        await Promise.all(
          map(this.updatedWorker.complianceChecks, async compliance => {
            if (
              compliance.complianceCheckName === RTW &&
              compliance.checkVerified
            ) {
              RIGHT_TO_WORK = await first(
                this.$refs.form.$refs.standardCompliance.$refs
                  .rightToWorkValidation
              ).validate();
            }
            if (process.env.VUE_APP_HIDE_COMPLIANCE_FOR_CSP === "true") {
              return;
            }
            if (
              compliance.complianceCheckName === REFERENCES &&
              compliance.checkVerified
            ) {
              EMPLOYMENT_HISTORY = await first(
                this.$refs.form.$refs.standardCompliance.$refs
                  .employmentValidation
              ).validate();
              this.isReferencesChecked = true;
            }
            if (
              compliance.complianceCheckName === FTF &&
              compliance.checkVerified
            ) {
              FACE_TO_FACE_INTERVIEW = await first(
                this.$refs.form.$refs.standardCompliance.$refs
                  .faceToFaceValidation
              ).validate();
            }
            if (
              compliance.complianceCheckName === DL &&
              compliance.checkVerified
            ) {
              DRIVING_LICENCE = await first(
                this.$refs.form.$refs.standardCompliance.$refs.DLExpiryDate
              ).$refs.validation.validate();
            }
          })
        );
        IS_VALID =
          RIGHT_TO_WORK &&
          EMPLOYMENT_HISTORY &&
          FACE_TO_FACE_INTERVIEW &&
          DRIVING_LICENCE;
      }
      if (this.dialogSteps.current === 2) {
        await Promise.all(
          map(this.updatedWorker.complianceChecks, async compliance => {
            if (
              compliance.complianceCheckName === DBS &&
              compliance.checkVerified
            ) {
              DBSV = await first(
                this.$refs.form.$refs.additionalCompliance.$refs.dbs
              ).$refs.validation.validate();
            }
            if (
              compliance.complianceCheckName === HCPC &&
              compliance.checkVerified
            ) {
              HCPC_CCWW_SSSC = await first(
                this.$refs.form.$refs.additionalCompliance.$refs.HCPCNumber
              ).$refs.validation.validate();

              HCPC_CCWW_SSSC = await first(
                this.$refs.form.$refs.additionalCompliance.$refs.HCPCExpiryDate
              ).$refs.validation.validate();
            }
            if (
              compliance.complianceCheckName === ST &&
              compliance.checkVerified
            ) {
              SAFEGUARDING = await first(
                this.$refs.form.$refs.additionalCompliance.$refs.STExpiryDate
              ).$refs.validation.validate();
            }
            if (
              compliance.complianceCheckName === QTS &&
              compliance.checkVerified
            ) {
              QTS_NCTL_QUALIFICATION = await first(
                this.$refs.form.$refs.additionalCompliance.$refs.QTSNumber
              ).$refs.validation.validate();
            }
            if (
              compliance.complianceCheckName === BL &&
              compliance.checkVerified
            ) {
              BARRED_LIST = await first(
                this.$refs.form.$refs.additionalCompliance.$refs.barredListDate
              ).$refs.validation.validate();
            }
            if (
              compliance.complianceCheckName === HS &&
              compliance.checkVerified
            ) {
              HEALTHANDSAFETY = await first(
                this.$refs.form.$refs.additionalCompliance.$refs
                  .hsCompletionDate
              ).$refs.validation.validate();
            }
            if (
              compliance.complianceCheckName === CT &&
              compliance.checkVerified
            ) {
              CYBERSECURITY = await first(
                this.$refs.form.$refs.additionalCompliance.$refs
                  .ctCompletionDate
              ).$refs.validation.validate();
            }
            if (
              compliance.complianceCheckName === GDPRT &&
              compliance.checkVerified
            ) {
              GDPR_TRAINING = await first(
                this.$refs.form.$refs.additionalCompliance.$refs
                  .gdprtCompletionDate
              ).$refs.validation.validate();
            }
            if (
              compliance.complianceCheckName === RF &&
              compliance.checkVerified
            ) {
              REGISTRATION_FORM =
                (await first(
                  this.$refs.form.$refs.additionalCompliance.$refs.RFExpiryDate
                ).$refs.validation.validate()) &&
                size(
                  first(
                    this.$refs.form.$refs.additionalCompliance.$refs
                      .complianceFiles
                  ).files
                );
            }
            if (
              compliance.complianceCheckName === CONTRACT &&
              compliance.checkVerified
            ) {
              CONTRACT_VALIDATION = size(
                first(
                  this.$refs.form.$refs.additionalCompliance.$refs
                    .complianceFiles
                ).files
              );
            }
            if (
              compliance.complianceCheckName === SMC &&
              compliance.checkVerified
            ) {
              SOCIAL_MEDIA_CHECK = size(
                first(
                  this.$refs.form.$refs.additionalCompliance.$refs
                    .complianceFiles
                ).files
              );
            }
          })
        );

        IS_VALID =
          DBSV &&
          HCPC_CCWW_SSSC &&
          QTS_NCTL_QUALIFICATION &&
          BARRED_LIST &&
          SAFEGUARDING &&
          HEALTHANDSAFETY &&
          CYBERSECURITY &&
          GDPR_TRAINING &&
          REGISTRATION_FORM &&
          CONTRACT_VALIDATION &&
          SOCIAL_MEDIA_CHECK;
      }
      if (IS_VALID) {
        this.showValidationErrors = false;
        this.dialogSteps.current++;
        this.steps.current++;
      }
    },
    stepBack() {
      this.dialogSteps.current--;
      this.steps.current--;
    },
    async handleRemoveFile() {
      this.isRemoving = true;
      try {
        await this.deleteComplianceFile(this.currentWorker.id);
        this.setWorkerComplianceFile(null);
      } finally {
        this.isRemoving = false;
      }
    },
    async handleSaveWorker() {
      this.isSaving = true;
      let { IS_VALID } = VALIDATIONS;
      if (this.steps.current === 1) {
        this.showValidationErrors = true;
        IS_VALID =
          (await this.$refs.form.$refs.basicDetailsValidation.validate()) &&
          (await this.handleWorkerAddress());
      }
      if (this.steps.current === 2) {
        this.showValidationErrors = true;
        IS_VALID =
          this.updatedWorker.incomeType !== "PAYE"
            ? (await this.handleWorkerIntermediary()) &&
              (await this.$refs.form.$refs.incomeType.validate())
            : true;
      }
      if (!IS_VALID) {
        this.isSaving = false;
        return;
      }
      await this.handleComplianceAndReferences();
      await this.updateWoker({
        updatedWorker: this.getWorkerDataToUpdate()
      })
        .then(() => {
          this.updateDialog.dialog = false;
          this.getIntermediaryCompany(this.updatedWorker.id);
          this.$forceUpdate();
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isSaving = false;
        });
    },
    getWorkerDataToUpdate() {
      switch (this.steps.current) {
        case 1: {
          const {
            id,
            firstName,
            lastName,
            email,
            phone,
            dob,
            addresses
          } = this.updatedWorker;
          return {
            id,
            firstName,
            lastName,
            email,
            phone,
            dob,
            addresses
          };
        }
        case 2: {
          const {
            id,
            incomeType,
            incomeTypeName,
            intermediaryOrganisation
          } = this.updatedWorker;
          return { id, incomeType, incomeTypeName, intermediaryOrganisation };
        }
        case 3: {
          const { id, cv } = this.updatedWorker;
          return { id, cv };
        }
        case 4:
        case 5:
        case 6: {
          const {
            id,
            yearsReferenced,
            complianceSupportingFile,
            userComplianceChecks,
            userReferences
          } = this.updatedWorker;
          return {
            id,
            yearsReferenced,
            complianceSupportingFile,
            userComplianceChecks,
            userReferences
          };
        }
        case 7: {
          const {
            id,
            gender,
            dda,
            ethnicGroupId,
            countryOfBirth
          } = this.updatedWorker;
          return {
            id,
            gender,
            dda,
            ethnicGroupId,
            countryOfBirth
          };
        }
      }
    },
    async handleComplianceAndReferences() {
      if (includes([4, 5, 6], this.steps.current)) {
        // get updated references
        await this.handleReferences();
        // includes compliance and references on update
        await this.addWorkerComplianceAndReferences();
      }
    },
    async addWorkerComplianceAndReferences() {
      this.updatedWorker.userComplianceChecks = [];
      this.updatedWorker.userReferences = [];
      await this.handleToBeDeletedReferences();
      if (this.updatedWorker.references.length > 0) {
        await Promise.all(
          map(this.updatedWorker.references, reference => {
            const {
              id,
              type,
              referenceTypeName,
              periodStartDate,
              periodEndDate,
              referenceReceivedDate
            } = reference;
            const attributes = {
              referenceTypeName,
              periodStartDate,
              periodEndDate,
              referenceReceivedDate
            };
            const userReference = { id, type, attributes };
            this.updatedWorker.userReferences.push(userReference);
          })
        );
      }
      await Promise.all(
        map(this.updatedWorker.complianceChecks, compliance => {
          const {
            id,
            type,
            complianceCheckName,
            checkVerified,
            documentTypeName,
            documentReferenceNumber,
            documentValidFromDate,
            documentValidToDate,
            registeringBody,
            checkHasUpdateService,
            files
          } = compliance;
          const attributes = {
            complianceCheckName,
            checkVerified,
            documentTypeName,
            documentReferenceNumber,
            documentValidFromDate,
            documentValidToDate,
            registeringBody,
            checkHasUpdateService,
            files
          };
          const userCompliance = { id, type, attributes };
          this.updatedWorker.userComplianceChecks.push(userCompliance);
        })
      );
    },
    async handleReferences() {
      if (!this.isReferencesChecked) {
        await this.deleteReferences();
        this.updatedWorker.references = [];
        return;
      }
      this.updatedWorker.references = await this.getReferences();
    },
    async getReferences() {
      const references = await filter(
        this.updatedWorker.references,
        reference => {
          if (!reference.isNew) {
            return reference;
          }
        }
      );
      const newReferences = await this.addNewReferences();
      return union(references, newReferences);
    },
    async addNewReferences() {
      const newReferences = [];
      await Promise.all(
        map(this.updatedWorker.newReferences, async reference => {
          delete reference.id;
          reference.user = { id: this.currentWorker.id };
          if (reference.periodStartDate) {
            await this.addNewReference(reference).then(data => {
              data.periodStartDate = formatDate({ date: data.periodStartDate });
              data.periodEndDate = formatDate({ date: data.periodEndDate });
              data.referenceReceivedDate = formatDate({
                date: data.referenceReceivedDate
              });
              newReferences.push(data);
            });
          }
        })
      );
      this.updatedWorker.newReferences = [];

      return newReferences;
    },
    async handleToBeDeletedReferences() {
      await Promise.all(
        map(this.updatedWorker.toBeDeletedReferences, async reference => {
          await this.deleteReference(reference.id).then(id =>
            remove(
              this.updatedWorker.references,
              reference => reference.id === id
            )
          );
        })
      );
      this.updatedWorker.toBeDeletedReferences = [];
    },
    async deleteReferences() {
      await Promise.all(
        map(this.updatedWorker.references, async reference => {
          await this.deleteReference(reference.id).then(id =>
            remove(
              this.updatedWorker.references,
              reference => reference.id === id
            )
          );
        })
      );
    },
    async handleWorkerAddress() {
      if (isEmpty(this.updatedWorker.address)) {
        return true;
      }
      if (this.updatedWorker.addresses.length === 0) {
        return await this.addNewAddress(this.updatedWorker.address)
          .then(id => {
            this.updatedWorker.address.id = id;
            this.updatedWorker.addresses.push(this.updatedWorker.address);
            return true;
          })
          .catch(() => {
            return false;
          });
      }
      return await this.updateAddress(this.updatedWorker.address)
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    async handleWorkerIntermediary() {
      let isSuccess = true;
      if (isEmpty(this.updatedWorker.company)) {
        return isSuccess;
      }
      if (isEmpty(this.updatedWorker.intermediaryOrganisation)) {
        this.updatedWorker.company.organisationType = "intermediary";
        this.updatedWorker.intermediaryOrganisation = this.updatedWorker.company;
        await this.createOrganisation(
          this.updatedWorker.intermediaryOrganisation
        )
          .then(id => {
            this.updatedWorker.company.id = id;
            isSuccess = true;
          })
          .catch(() => {
            isSuccess = false;
          });
      } else {
        const {
          id,
          type,
          name,
          organisationType,
          companyRegistrationNumber
        } = this.updatedWorker.company;
        isSuccess = await this.updateOrganisation({
          id,
          type,
          name,
          organisationType,
          companyRegistrationNumber
        })
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
      }
      if (!isEmpty(this.updatedWorker.companyAddress)) {
        if (!this.updatedWorker.companyAddress.id) {
          await this.addNewAddress(this.updatedWorker.companyAddress)
            .then(id => {
              this.updatedWorker.company.addresses = [];
              this.updatedWorker.company.addresses.push(
                this.updatedWorker.companyAddress
              );
              this.updatedWorker.companyAddress.id = id;
              this.updateOrganisation(this.updatedWorker.company);
              this.getIntermediaryCompany(this.updatedWorker.id);
              isSuccess = true;
            })
            .catch(() => {
              isSuccess = false;
            });
        } else {
          isSuccess = await this.updateAddress(
            this.updatedWorker.companyAddress
          )
            .then(() => {
              return true;
            })
            .catch(() => {
              return false;
            });
        }
      }
      return isSuccess;
    },
    handleViewCV() {
      this.fileDialog.src = this.cvUrlWithToken;
      this.fileDialog.title = "CV";
      this.fileDialog.dialog = true;
    },
    showComplianceSupportingFile() {
      this.fileDialog.src = this.complianceFileUrlWithAuth;
      this.fileDialog.title = this.complianceSupportingFile.name;
      this.fileDialog.dialog = true;
    },
    hasValue(value) {
      if (typeof value === "object" && value != null) {
        return value.length > 0;
      }
      return Boolean(value);
    },
    handleCancelEdit() {
      this.formKey += 1;
    }
  },
  filters: {
    hasValue(value) {
      return value ? value : "-";
    },
    ddaAnswer(value) {
      return value ? "Yes" : "No";
    },
    awrLabel(value) {
      return value ? "Post-AWR" : "Pre-AWR";
    }
  }
};
</script>

<style lang="scss">
.worker {
  &__profile {
    width: 100%;
    @media only screen and (max-width: $tablet-breakpoint - 1) {
      flex-direction: column;
    }
    &--left {
      flex: 0.7;
      margin-bottom: 30px;

      h5 {
        position: relative;
        z-index: 1;
      }
    }
    &--right {
      flex: 0.3;
      margin-left: 10px;

      @media only screen and (max-width: $tablet-breakpoint - 1) {
        margin-bottom: 30px;
      }
    }
    &--cv {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &--compliance {
      display: flex;
      flex-wrap: wrap;
      padding: 5px;
      position: relative;
      margin-top: 10px;

      p.compliance--count {
        position: absolute;
        top: -50px;
        left: 215px;
        color: $secondary-text;
      }
    }
    h3 {
      z-index: 1;
      font-size: 22px;
      margin-bottom: 5px;
    }
    hr {
      margin-top: 25px;
      border-top: $border-light;
      margin-bottom: 10px;
    }
  }
}
</style>
